.CurrentMonthprogressmain{
    width: 99%;
    margin: auto;
    background-color: white;
}
.CurrentMonthprogress{
    background-color: var(--backColor);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    padding: 0.5rem; 
}

.CurrentMonthprogress .CurrentMonthprogresslogo{
    display: flex;
    justify-content: center;
    align-items: center;

} 
.CurrentMonthprogress .totalizerlogo{
    padding: 0 0.5rem;
}
.borderline{
    border: 1px solid gray;
    margin: 1rem 0.1rem;
}
.targetmain{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
@media screen and (max-width:500px) {
    .CurrentMonthprogress{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
    }
}