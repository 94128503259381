.loading{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MainDepartmentWithFooter {
    height: 100%;
    position: relative;
    color: black;
    height: 100%;
    width: 100%;

    .departmentMainContainer {
        box-sizing: border-box;

        .departmentMain {
            padding: 1rem;

            .departmentHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1rem;


                .headleft {
                    display: flex;
                    align-items: center;
                    font-size: 1.5rem;
                    .icon{
                        font-size: 2.5rem;
                    }
                }
            }


            .departmentCard {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                padding: 1rem;
                border-radius: 10px;
                width: 100%;
                align-items: center;
                align-self: center;
                cursor: pointer;


                .departmentmain {
                    display: flex;
                    flex-direction: column;
                    box-shadow: 0 0 4px 7px rgba(0, 0, 0, .05);
                    height: 8rem;
                    width: 8rem;
                    border-radius: 5px;
                    align-items: center;
                    margin: 1rem;
                    text-align: center;
                    font-size: 12px;
                    overflow: hidden;
                    font-size: 1.2rem;
                    .branchImg {
                        width: 7rem;
                        height: 4rem;
                        padding: 0.4rem;
                    }
                    .deviceName {
                        padding: 3px 0;
                        cursor: pointer;
                        font-size: 11px;
                        height: 2rem;
                    }
                    .deviceID {
                        font-size: 10px;
                    }
                    .deviceData{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        font-size: 13px;
                        width: 100%;
                        padding: 10px;
                    }
                }
            }
        }
    }
}