.machineHeader{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 10px;
    align-items: center;
}
.DeviceIDheading{
    color: gray;
}

.machineimagelogo{
    height: 4rem;
    width: 7rem;
}
.roomName{
    font-size: 1rem;
}

.machineheading{
    font-size: 0.8rem;
    color: gray;
}
.datetimeupdated{
    margin-right: 0.5rem;
}
@media screen and (max-width:750px) {
    .machineHeader{
        flex-wrap: wrap;
    }
    .machineheading{
        display: none;
    }
    .datetimeupdated{
        margin-right: 0rem;
    }
}