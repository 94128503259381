
.todayprogressmain{
    width: 50%;
    min-height: 20vh;
    margin: 0.1rem;
    background-color: white;
}
.todayprogress{
    background-color: var(--backColor);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    padding: 0.5rem;
    height: 3.5rem; 
}

.todayprogress .todayprogresslogo{
    display: flex;
    justify-content: center;
    align-items: center;

} 
.todayprogress .totalizerlogo{
    padding: 0 0.5rem;
}

@media screen and (max-width:900px){
    .todayprogressmain{
        width: 100%;
    }
}
@media screen and (max-width:500px) {
    
    .todayprogress{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
        height: auto;
    }
}