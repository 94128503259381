.machineHeaderLogin{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.machineimagelogoLogin{
    height: 3rem;
    width: 6rem;
}
.roomNameLogin{
    font-size: 1rem;
}

.machineheadingLogin{
    font-size: 0.8rem;
    color: gray;
    font-weight: bold;
    /* width: 30%; */
}
.headSysNam{
    font-size: 11px;
}
.datetimeupdatedLogin{
    margin-right: 2rem;
}
.boldmachineheadingLogin{
    color: white;
}
@media screen and (max-width:750px) {
    .machineHeaderLogin{
        flex-wrap: wrap;
    }
}
