.footer{
    display: flex;
    justify-content: space-between;
    margin: 0 2rem -1rem 2rem;
}
.btns{
    display: flex;
    align-items: center;
}
.footer .develophead{
    font-size: 12px;
}
.footer .develophead1{
    font-size: 12px;
    display: none;
}
.footer .projectName{
    font-weight: bold;
    font-size: 13px;
}
.footer .develop{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
}
.footer .hunchlogo{
    height: 3.5rem;
    width: 5rem;
    padding-left: 0.2rem;
}

@media screen and (max-width:500px) {
    .footer .develophead{
        display: none;
    }
    .footer .develophead1{
        display: block;
    }
}