.totalizerlogo{
    cursor: pointer;
    background-color: transparent;
}
.popupContainer{
    width: 100%;
    height: 35vh;
    position: static;
    top: 4rem;
    right: 10rem;
}
.btndatetime{
    background-color: transparent;
}
.popupMainContainer{
    background-color: transparent;
    width: 40rem;
    height: 30vh;
    /* position: absolute; */
    justify-content: center;
    display: flex;
    align-items: center;
}
.LastMonthprogressmain{
    width: 99%;
    margin: auto;
    background-color: white;
}
.LastMonthprogress{
    background-color: var(--backColor);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    padding: 0.5rem; 
}

.LastMonthprogress .LastMonthprogresslogo{
    display: flex;
    justify-content: center;
    align-items: center;

} 
.LastMonthprogress .totalizerlogo{
    padding: 0 0.5rem;
}
.borderline{
    border: 1px solid gray;
    margin: 1rem 0.1rem;
}
.targetmain{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
